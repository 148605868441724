<!-- 支付宝配置 -->
<template>
    <div>
        支付宝配置
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    components: {},

    mounted() { },

    methods: {}
}

</script>
<style scoped></style>